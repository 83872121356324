.newZtFormBox[data-v-f5e2d77a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.newZtFormBox .imgContainer[data-v-f5e2d77a] {
  width: 40%;
  border: 1px solid #ccc;
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.newZtFormBox .imgContainer .imgContent[data-v-f5e2d77a] {
  width: 100%;
  height: 216px;
  position: relative;
}
.newZtFormBox .imgContainer .imgContent img[data-v-f5e2d77a] {
  width: 100%;
  height: 100%;
}
.newZtFormBox .imgContainer .imgContent .btn[data-v-f5e2d77a] {
  width: 100px;
  height: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.newZtFormBox .imgContainer .imgHover[data-v-f5e2d77a] {
  background: #000000;
  opacity: 0.4;
}
[data-v-f5e2d77a] .el-tabs__header {
  padding: 0 12px;
  margin: 0;
  background: #fff;
}
[data-v-f5e2d77a] .el-input-number {
  width: 180px;
}
[data-v-f5e2d77a] .paperCurd .avue-crud .el-table {
  height: calc(100vh - 406px) !important;
  max-height: calc(100vh - 406px) !important;
}
.knowledgeTree .common_tree_handle .el-tree[data-v-f5e2d77a] {
  height: calc(100vh - 500px) !important;
}
[data-v-f5e2d77a] .avue-tree {
  height: calc(100vh - 417px);
}
[data-v-f5e2d77a] .serviceType .avue-crud .el-table {
  height: auto !important;
}
[data-v-f5e2d77a] .serviceType .avue-crud .el-table .avue-crud__empty {
  padding: 0;
}
[data-v-f5e2d77a] .serviceType .avue-crud .el-table .el-empty {
  padding: 0;
}
[data-v-f5e2d77a] .serviceType .avue-crud .el-table .el-empty__description {
  margin-top: 0;
}
[data-v-f5e2d77a] .common_tree_handle .el-tree {
  /* height: 100% !important; */
  height: calc(100vh - 459px) !important;
  overflow-y: scroll;
}
[data-v-f5e2d77a] .el-dialog__body {
  padding: 15px 20px !important;
}